import React from 'react';
import ExamTemplate from '../../components/ExamTemplate';
import { Progress, ProgressMeasureLocation } from '@patternfly/react-core';


function EX188() {
  const tabs = [
    {
      title: 'Deploy the OpenShift Virtualization Operator',
      content: (
        <div>
          <Progress
            value={100}
            title="Completion"
            measureLocation={ProgressMeasureLocation.top}
          />
          <h2>Grant web console user cluster-admin access to install operator from OperatorHub</h2>
          <p>
            Use the following command to grant cluster-admin access:
          </p>
          <pre>
            <code>oc adm policy add-cluster-role-to-user cluster-admin &lt;user-name&gt;</code>
          </pre>
        </div>
      ),
    },
    {
      title: 'Run and Access Virtual Machines',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Create VMs from Template</h2>
          <p>
            Learn how to create virtual machines using predefined templates.
          </p>
        </div>
      ),
    },
    {
      title: 'Configure Kubernetes Networking for VMs',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            To configure a service to access a VM on a node or a pod, you must start with a label.
          </p>
          <ul>
            <li>On the VM, the label goes to the <code>spec.template.metadata.labels</code> section.</li>
            <li>You can apply the label to the virt-launcher pod if you don't want to restart the VM; the label applies immediately.</li>
            <li>You then apply the label to the service.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Configure Kubernetes Service',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Service Types</h2>
          <ul>
            <li><strong>ClusterIP</strong>: For connections within the cluster ONLY.</li>
            <li><strong>NodePort</strong>: For external client access.</li>
            <li><strong>LoadBalancer</strong>: Distributes traffic across multiple servers.</li>
          </ul>
        </div>
      ),
    },
    // Add more tabs for each exam objective
    {
      title: 'Perform Node Maintenance and Updates',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            Understand how to perform node maintenance and update OpenShift Virtualization.
          </p>
        </div>
      ),
    },
    // ... Add other exam objectives similarly
  ];

  return (
    <ExamTemplate title="OpenShift Virtualization Exam Objectives" tabs={tabs} />
  );
}

export default EX188;
