import React from 'react';
import { Title, PageSection } from '@patternfly/react-core';


function About() {
  return (
    <PageSection>
    <Title headingLevel="h1" size="4xl">
      About OpenShift Almanac
    </Title>
  </PageSection>
  );
}

export default About;
