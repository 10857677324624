import React from 'react';
import CommandTable from '../../components/CommandTable';

function OcCommands() {
  const ocCommands = [
    {
      command: 'oc login',
      tool: 'oc',
      function: 'Log in to an OpenShift cluster.',
    },
    {
      command: 'oc new-project <project-name>',
      tool: 'oc',
      function: 'Create a new project/namespace in the OpenShift cluster.',
    },
    {
      command: 'oc get pods',
      tool: 'oc',
      function: 'List all pods in the current namespace.',
    },
    {
      command: 'oc get nodes',
      tool: 'oc',
      function: 'List all nodes in the OpenShift cluster.',
    },
    {
      command: 'oc describe pod <pod-name>',
      tool: 'oc',
      function: 'Display detailed information about a specific pod.',
    },
    {
      command: 'oc get svc',
      tool: 'oc',
      function: 'List all services in the current namespace.',
    },
    {
      command: 'oc expose svc <service-name>',
      tool: 'oc',
      function: 'Expose a service to create a route, making it externally accessible.',
    },
    {
      command: 'oc delete pod <pod-name>',
      tool: 'oc',
      function: 'Delete a specific pod.',
    },
    {
      command: 'oc adm cordon <node-name>',
      tool: 'oc',
      function: 'Mark a node as unschedulable (cordon), preventing new pods from being scheduled on it.',
    },
    {
      command: 'oc adm drain <node-name>',
      tool: 'oc',
      function: 'Safely evict pods from a node and prepare it for maintenance.',
    },
    {
      command: 'oc scale deployment <name> --replicas=<count>',
      tool: 'oc',
      function: 'Scale a deployment to the desired number of replicas.',
    },
    {
      command: 'oc apply -f <file.yaml>',
      tool: 'oc',
      function: 'Apply changes to resources described in a YAML or JSON file.',
    },
    {
      command: 'oc patch',
      tool: 'oc',
      function: 'Update specific fields of a resource without editing the whole resource definition.',
    },
    {
      command: 'oc adm top nodes',
      tool: 'oc',
      function: 'Show resource usage (CPU/Memory) for all nodes.',
    },
    {
      command: 'oc adm top pods',
      tool: 'oc',
      function: 'Show resource usage (CPU/Memory) for all pods in a namespace.',
    },
    {
      command: 'oc rsh <pod-name>',
      tool: 'oc',
      function: 'Open a shell into a running pod.',
    },
    {
      command: 'oc logs <pod-name>',
      tool: 'oc',
      function: 'View the logs of a pod.',
    },
    {
      command: 'oc exec <pod-name> -- <command>',
      tool: 'oc',
      function: 'Execute a command inside a running pod.',
    },
    // ... add any additional commands
  ];

  return (
    <div className="container mx-auto py-16">
      <h1 className="text-4xl font-bold mb-8">`oc` Command Reference</h1>
      <p className="mb-6">
        The <code>oc</code> command-line tool is the primary tool used for administering OpenShift clusters.
      </p>
      <CommandTable commands={ocCommands} />
    </div>
  );
}

export default OcCommands;
