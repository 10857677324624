import React from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@patternfly/react-core/dist/styles/base.css';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
// Pages
import Landing from './features/pages/Landing';
import Updates from './features/pages/Updates';
import Tutorials from './features/pages/Tutorials';
import Exams from './features/pages/Exams';
import CliGuides from './features/pages/CliGuides';
import About from './features/pages/About';
// Tutorials
import Podman from './features/tutorials/Podman';
import Virtualization from './features/tutorials/Virtualization';
import Rosa from './features/tutorials/ROSA';
import Aro from './features/tutorials/ARO';
import OpenShift from './features/tutorials/OpenShift';
// Clis
import CliPodman from './features/cli/PodmanCommands';
import CliOc from './features/cli/OcCommands';
import CliVirtctl from './features/cli/VirtctlCommands';
import CliRosa from './features/cli/RosaCommands';
import CliAro from './features/cli/AroCommands';
// Exams
import EX188 from './features/exams/EX188';
import EX280 from './features/exams/EX280';
import EX288 from './features/exams/EX288';
import EX316 from './features/exams/EX316';
import EX380 from './features/exams/EX380';


// Import other pages similarly

function App() {
  return (
    <Router>
      <Navbar />
      <Toaster position="top-right" />
      <div className="p-4">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/tutorials" element={<Tutorials />} />
          <Route path="/exams" element={<Exams />} />
          <Route path="/cli-guides" element={<CliGuides />} />
          <Route path="/tutorials/podman" element={<Podman />} />
          <Route path="/tutorials/virtualization" element={<Virtualization />} />
          <Route path="/tutorials/rosa" element={<Rosa />} />
          <Route path="/tutorials/aro" element={<Aro />} />
          <Route path="/tutorials/openshift" element={<OpenShift />} />
          <Route path="/tutorials/openshift-platform" element={<OpenShift />} />
          <Route path="/exams/EX188" element={<EX188 />} />
          <Route path="/exams/EX280" element={<EX280 />} />
          <Route path="/exams/EX288" element={<EX288 />} />
          <Route path="/exams/EX380" element={<EX380 />} />
          <Route path="/exams/EX316" element={<EX316 />} />
          <Route path="/cli/podman" element={<CliPodman />} />
          <Route path="/cli/oc" element={<CliOc />} />
          <Route path="/cli/virtctl" element={<CliVirtctl />} />
          <Route path="/cli/rosa" element={<CliRosa />} />
          <Route path="/cli/aro" element={<CliAro />} />
          {/* Add routes for other pages */}
        </Routes>
        {/* Footer */}
        <Footer />
      </div>
    </Router>

  );
}

export default App;
