import React, { useState } from 'react';
import { ClipboardIcon, CheckIcon } from '@heroicons/react/outline';
import { toast } from 'react-hot-toast';

function CommandTable({ commands }) {
  const [copiedCommand, setCopiedCommand] = useState(null);

  const handleCopy = (command) => {
    navigator.clipboard.writeText(command).then(() => {
      setCopiedCommand(command);
      toast.success('Command copied to clipboard');
      setTimeout(() => setCopiedCommand(null), 2000); // Reset after 2 seconds
    }, (err) => {
      toast.error('Failed to copy command');
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow-md rounded my-6">
        <thead>
          <tr>
            <th className="py-3 px-5 bg-gray-200 font-bold uppercase text-sm text-gray-600">Command</th>
            <th className="py-3 px-5 bg-gray-200 font-bold uppercase text-sm text-gray-600">Tool</th>
            <th className="py-3 px-5 bg-gray-200 font-bold uppercase text-sm text-gray-600">Function</th>
            <th className="py-3 px-5 bg-gray-200"></th>
          </tr>
        </thead>
        <tbody>
          {commands.map((item, index) => (
            <tr key={index} className="border-b">
                <td className="py-4 px-5 text-gray-700 break-words">
                <button
                  onClick={() => handleCopy(item.command)}
                  className="text-blue-500 hover:text-blue-700"
                  title="Copy Command"
                >
                {copiedCommand === item.command ? (
                <CheckIcon className="h-5 w-5" />
                ) : (
                <ClipboardIcon className="h-5 w-5" />
                )}
                </button>
              </td>
              <td className="py-4 px-5 text-gray-700 font-mono">
                <code>{item.command}</code>
              </td>
              <td className="py-4 px-5 text-gray-700">
                {item.tool}
              </td>
              <td className="py-4 px-5 text-gray-700">
                {item.function}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CommandTable;
