import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Dropdown({ title, items }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={() => setOpen(!open)}
          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white hover:text-gray-300"
        >
          {title}
        </button>
      </div>

      {open && (
        <div className="origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white">
          <div className="py-1">
            {items.map((item) => (
              <Link
                key={item.label}
                to={item.path}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => setOpen(false)}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
