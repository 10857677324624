import React from 'react';
import { Title, PageSection } from '@patternfly/react-core';

function ROSA() {
  return (
    <PageSection>
      <Title headingLevel="h1" size="4xl">
        Tutorials: Red Hat OpenShift on AWS (ROSA)
      </Title>
    </PageSection>
  );
}

export default ROSA;