import React from 'react';
import { Title, PageSection } from '@patternfly/react-core';

function ARO() {
  return (
    <PageSection>
      <Title headingLevel="h1" size="4xl">
        Tutorials: Azure for Red Hat OpenShift (ARO)
      </Title>
    </PageSection>
  );
}

export default ARO;