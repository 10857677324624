import React from 'react';
import TutorialTemplate from '../../components/TutorialTemplate';

function OpenShift() {
  const tabs = [
    {
      title: 'OpenShift Overview',
      content: (
        <div>
          <p>Welcome to the tutorial on deploying applications in OpenShift.</p>
          {/* Add more content */}
        </div>
      ),
    },
    {
      title: 'Prerequisites',
      content: (
        <div>
          <ul>
            <li>Access to an OpenShift cluster</li>
            <li>Installed OpenShift CLI (`oc`)</li>
            {/* Add more prerequisites */}
          </ul>
        </div>
      ),
    },
    {
      title: 'Steps',
      content: (
        <div>
          <h2>Step 1: Log in to OpenShift</h2>
          <p>Use the following command to log in:</p>
          <pre>
            <code>oc login --token=YOUR_TOKEN --server=YOUR_SERVER</code>
          </pre>
          {/* Add more steps */}
        </div>
      ),
    },
    {
      title: 'Conclusion',
      content: (
        <div>
          <p>You've successfully deployed an application on OpenShift!</p>
          {/* Add more conclusion content */}
        </div>
      ),
    },
  ];

  return (
    <TutorialTemplate title="Deploying Applications on OpenShift" tabs={tabs} />
  );
}

export default OpenShift;
