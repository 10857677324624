import React from 'react';
import CommandTable from '../../components/CommandTable';

function AroCommands() {
  const aroCommands = [
    {
      command: 'aro create cluster',
      tool: 'aro',
      function: 'Create a new aro (Red Hat OpenShift on AWS) cluster.',
    },
    {
      command: 'aro delete cluster',
      tool: 'aro',
      function: 'Delete a aro cluster.',
    },
    // ... add the rest of the aro commands here
  ];

  return (
    <div className="container mx-auto py-16">
      <h1 className="text-4xl font-bold mb-8">`aro` Command Reference</h1>
      <p className="mb-6">
        The <code>aro</code> command-line tool is specific to managing Red Hat OpenShift on AWS (aro).
      </p>
      <CommandTable commands={aroCommands} />
    </div>
  );
}

export default AroCommands;
