import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto px-4">
        {/* Top Section: Navigation Links */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <ul className="flex flex-col list-none md:flex-row">
              <li className="md:mr-6 mb-2 md:mb-0">
                <Link to="/" className="hover:text-gray-400">Home</Link>
              </li>
              <li className="md:mr-6 mb-2 md:mb-0">
                <Link to="/updates" className="hover:text-gray-400">Updates</Link>
              </li>
              <li className="md:mr-6 mb-2 md:mb-0">
                <Link to="/tutorials" className="hover:text-gray-400">Tutorials</Link>
              </li>
              <li className="md:mr-6 mb-2 md:mb-0">
                <Link to="/cli/podman" className="hover:text-gray-400">CLI Guides</Link>
              </li>
              <li className="md:mr-6 mb-2 md:mb-0">
                <Link to="/exams" className="hover:text-gray-400">Exam Prep</Link>
              </li>
              <li className="md:mr-6 mb-2 md:mb-0">
                <Link to="/about" className="hover:text-gray-400">About</Link>
              </li>
              {/* Add more links as needed */}
            </ul>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-700 my-4"></div>

        {/* Bottom Section: Disclaimers and Copyright */}
        <div className="flex flex-col md:flex-row justify-between items-center text-sm">
          <p className="mb-2 md:mb-0">
            &copy; {currentYear} OpenShift Almanac. All rights reserved.
          </p>
          <p className="text-center md:text-right">
            This site is not affiliated with Red Hat, Inc. All trademarks are the property of their respective owners.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
