import React from 'react';
import { Title, PageSection } from '@patternfly/react-core';
import { Link } from 'react-router-dom';

function Exams() {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-red-700 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">
            Red Hat OpenShift Container Platform Tutorials
          </h1>
          <p className="text-xl mb-8">
            Your comprehensive resource for all OpenShift updates
          </p>
          <Link
            to="/updates"
            className="bg-white text-red-700 font-semibold py-2 px-4 rounded"
          >
            Explore Updates
          </Link>
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto py-16">
        <h2 className="text-3xl font-bold text-center mb-12">
          What You'll Find
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Feature Item */}
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-xl font-bold mb-2">OpenShift Updates</h3>
            <p>
              Stay up-to-date with the latest releases and features of OpenShift.
            </p>
          </div>
          {/* Repeat for other features */}
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-xl font-bold mb-2">Tutorials</h3>
            <p>
              Step-by-step guides to help you master OpenShift.
            </p>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-xl font-bold mb-2">CLI Reference Guides</h3>
            <p>
              Quick access to common commands for podman, oc, virtctl, and more.
            </p>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-xl font-bold mb-2">Exam Prep</h3>
            <p>
              Prepare for exams.
            </p>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-xl font-bold mb-2">Virtualization Reference Guides</h3>
            <p>
              Learn OpenShift virtualization capabilities.
            </p>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-xl font-bold mb-2">CLI Reference Guides</h3>
            <p>
              Quick access to common commands for podman, oc, virtctl, and more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exams;