import React from 'react';
import CommandTable from '../../components/CommandTable';

function VirtctlCommands() {
  const virtctlCommands = [
    {
      command: 'rosa create cluster',
      tool: 'rosa',
      function: 'Create a new ROSA (Red Hat OpenShift on AWS) cluster.',
    },
    {
      command: 'rosa delete cluster',
      tool: 'rosa',
      function: 'Delete a ROSA cluster.',
    },
    // ... add the rest of the rosa commands here
  ];

  return (
    <div className="container mx-auto py-16">
      <h1 className="text-4xl font-bold mb-8">`rosa` Command Reference</h1>
      <p className="mb-6">
        The <code>virtctl</code> command-line tool is specific to managing Red Hat OpenShift on AWS (ROSA).
      </p>
      <CommandTable commands={virtctlCommands} />
    </div>
  );
}

export default VirtctlCommands;
