import React from 'react';
import CommandTable from '../../components/CommandTable';

function PodmanCommands() {
  const podmanCommands = [
    {
      command: 'podman create cluster',
      tool: 'podman',
      function: 'Create a new podman (Red Hat OpenShift on AWS) cluster.',
    },
    {
      command: 'podman delete cluster',
      tool: 'podman',
      function: 'Delete a podman cluster.',
    },
    // ... add the rest of the podman commands here
  ];

  return (
    <div className="container mx-auto py-16">
      <h1 className="text-4xl font-bold mb-8">`podman` Command Reference</h1>
      <p className="mb-6">
        The <code>podman</code> command-line tool is specific to managing Red Hat OpenShift on AWS (podman).
      </p>
      <CommandTable commands={podmanCommands} />
    </div>
  );
}

export default PodmanCommands;
