import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';

function Navbar() {
  const cliItems = [
    { label: 'podman', path: '/cli/podman' },
    { label: 'oc', path: '/cli/oc' },
    { label: 'virtctl', path: '/cli/virtctl' },
    { label: 'rosa', path: '/cli/rosa' },
    { label: 'aro', path: '/cli/aro' },
  ];

  const tutorialItems = [
    { label: 'Podman', path: '/tutorials/Podman' },
    { label: 'ARO', path: '/tutorials/ARO' },
    { label: 'Virtualization', path: '/tutorials/Virtualization' },
    { label: 'ROSA', path: '/tutorials/ROSA' },
    { label: 'ARO', path: '/tutorials/ARO' },
    { label: 'OpenShift', path: '/tutorials/OpenShift' },
  ];

  const examItems = [
    { label: 'EX188', path: '/exams/EX188' },
    { label: 'EX280', path: '/exams/EX280' },
    { label: 'EX288', path: '/exams/EX288' },
    { label: 'EX316', path: '/exams/EX316' },
    { label: 'EX380', path: '/exams/EX380' },
  ];

  return (
    <nav className="bg-red-700 p-4">
      <ul className="flex justify-around list-none text-white font-semibold">
        <li>
          <Link to="/" className="hover:text-gray-300">Home</Link>
        </li>
        <li>
          <Link to="/updates" className="hover:text-gray-300">Updates</Link>
        </li>
        <li>
          <Dropdown title="Tutorials" items={tutorialItems} />
        </li>
        <li>
          <Dropdown title="CLI Guides" items={cliItems} />
        </li>
        <li>
          <Dropdown title="Exam Prep" items={examItems} />
        </li>
        <li>
          <Link to="/about" className="hover:text-gray-300">About</Link>
        </li>
        {/* Add other links */}
      </ul>
    </nav>
  );
}

export default Navbar;
